import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: 'employee/list', loadChildren: () => import('./pages/employees/list/list.module').then(m => m.ListModule) },
  { path: 'employee/new', loadChildren: () => import('./pages/employees/new/new.module').then(m => m.NewModule) },
  { path: 'employee/details', loadChildren: () => import('./pages/employees/details/details.module').then(m => m.DetailsModule) },
  { path: 'employee/edit', loadChildren: () => import('./pages/employees/edit/edit.module').then(m => m.EditModule) },
  { path: 'stores/list', loadChildren: () => import('./pages/stores/list/list.module').then(m => m.ListModule) },
  { path: 'stores/new', loadChildren: () => import('./pages/stores/new/new.module').then(m => m.NewModule) },
  { path: 'stores/details', loadChildren: () => import('./pages/stores/details/details.module').then(m => m.DetailsModule) },
  { path: 'stores/edit', loadChildren: () => import('./pages/stores/edit/edit.module').then(m => m.EditModule) },
  { path: 'products/list', loadChildren: () => import('./pages/products/list/list.module').then(m => m.ListModule) },
  { path: 'products/new', loadChildren: () => import('./pages/products/new/new.module').then(m => m.NewModule) },
  { path: 'products/details', loadChildren: () => import('./pages/products/details/details.module').then(m => m.DetailsModule) },
  { path: 'products/edit', loadChildren: () => import('./pages/products/edit/edit.module').then(m => m.EditModule) },
  { path: 'client/list', loadChildren: () => import('./pages/clients/list/list.module').then(m => m.ListModule) },
  { path: 'client/new', loadChildren: () => import('./pages/clients/new/new.module').then(m => m.NewModule) },
  { path: 'client/details', loadChildren: () => import('./pages/clients/details/details.module').then(m => m.DetailsModule) },
  { path: 'client/edit', loadChildren: () => import('./pages/clients/edit/edit.module').then(m => m.EditModule) },
  { path: 'orders/list', loadChildren: () => import('./pages/orders/list/list.module').then(m => m.ListModule) },
  { path: 'orders/new', loadChildren: () => import('./pages/orders/new/new.module').then(m => m.NewModule) },
  { path: 'orders/details', loadChildren: () => import('./pages/orders/details/details.module').then(m => m.DetailsModule) },
  { path: 'orders/edit', loadChildren: () => import('./pages/orders/edit/edit.module').then(m => m.EditModule) },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
