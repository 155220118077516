import { Store } from 'src/app/shared/models/store.interface';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class StoresService {
  stores: Observable<Store[]>;

  private storesCollection: AngularFirestoreCollection<Store>;

  constructor(private readonly afs: AngularFirestore) {
    this.storesCollection = afs.collection<Store>('stores');
    this.getStores();
  }


  onDeleteStores(empId: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.storesCollection.doc(empId).delete();
        resolve(result);
      } catch (err) {
        reject(err.message);
      }
    });
  }

  onSaveStore(store: Store, empId: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        const id = empId || this.afs.createId();
        const data = { id, ...store };
        const result = await this.storesCollection.doc(id).set(data);
        resolve(result);
      } catch (err) {
        reject(err.message);
      }
    });
  }


  private getStores(): void {
    this.stores = this.storesCollection.snapshotChanges().pipe(
      map(actions => actions.map(a => a.payload.doc.data() as Store))
    );
  }
}
