<form class="mt-3 col-md-8 offset-md-2" (ngSubmit)="onSave()" [formGroup]="employeeForm">
  <div class="form-row">
    <div class="form-group col-md-6">
      <input type="text" class="form-control" placeholder="Nombre" formControlName="name" [class]="isValidField('name')">
      <div class="invalid-feedback">
        <span *ngIf="employeeForm.get('name').errors?.required">
          This field is required.
        </span>
      </div>
    </div>
    <div class="form-group col-md-6">
      <select class="form-control" placeholder="Tipo de usuario" formControlName="type" id="" [class]="isValidField('type')">
        <option value="Repartidor">Repartidor</option>
        <option value="Vendedor">Vendedor</option>
        <option value="Moderador">Moderador</option>
        <option value="Administrativo">Administrativo</option>
        <option value="Vendedor Piso">Vendedor Piso</option>
      </select>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-6">
      <input type="text" class="form-control" placeholder="email" formControlName="email"
        [class]="isValidField('email')">
      <div class="invalid-feedback">
        <span *ngIf="employeeForm.get('email').errors?.required">
          This field is required.
        </span>
        <span *ngIf="employeeForm.get('email').errors?.pattern">
          Enter a valid email.
        </span>
      </div>
    </div>
    <div class="form-group col-md-6">
      <input type="text" class="form-control" placeholder="Start Date 01/02/2021" formControlName="startDate"
        [class]="isValidField('startDate')">
      <div class="invalid-feedback">
        <span *ngIf="employeeForm.get('startDate').errors?.required">
          This field is required.
        </span>
      </div>
    </div>
  </div>
  <button type="submit" class="btn bg-dark btn-block text-light">Save</button>
  <button type="button" class="btn btn-secondary btn-danger mt-2" (click)="onGoBackToList()">Go Back to list</button>
</form>

