import { StoresService } from '../../../pages/stores/stores.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '../../models/store.interface';
import swal from'sweetalert2';


@Component({
  selector: 'app-store-form',
  templateUrl: './store-form.component.html',
  styleUrls: ['./store-form.component.scss']
})
export class StoreFormComponent implements OnInit {

  store: Store;
  storeForm: FormGroup;

  private isEmail = /\S+@\S+\.\S+/;

  constructor(private router: Router, private fb: FormBuilder, private storesSvc: StoresService) {
    const navigation = this.router.getCurrentNavigation();
    this.store = navigation?.extras?.state?.value;
    this.initForm();
  }

  ngOnInit(): void {
    if (typeof this.store === 'undefined') {
      this.router.navigate(['new']);
    } else {
      this.storeForm.patchValue(this.store);
    }
  }

  onSave(): void {
    console.log('Saved', this.storeForm.value);
    if (this.storeForm.valid) {
      const store = this.storeForm.value;
      const storeId = this.store?.id || null;
      this.storesSvc.onSaveStore(store, storeId);

      swal.fire({
        icon: 'success',
        text: 'Registro exitoso!',
        confirmButtonColor: "#772953",
      });

      this.storeForm.reset();
      this.onGoBackToList();
    }else{
      swal.fire({
        icon: 'warning',
        text: 'Por favor llena los campos faltantes',
        confirmButtonColor: "#772953",
      })
    }

  }

  onGoBackToList(): void {
    this.router.navigate(['stores/list']);
  }

  isValidField(field: string): string {
    const validatedField = this.storeForm.get(field);
    return (!validatedField.valid && validatedField.touched)
      ? 'is-invalid' : validatedField.touched ? 'is-valid' : '';
  }

  private initForm(): void {
    this.storeForm = this.fb.group({
      name: ['', [Validators.required]],
      type: ['', [Validators.required]],
      phone_1: ['', [Validators.required]],
      phone_2: ['', [Validators.required]],
      responsible_name: ['', [Validators.required]],
      address: ['', [Validators.required]],
      square: ['', [Validators.required]],
      municipality: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      active: ['', [Validators.required]],
      //template_invoice: ['', [Validators.required]],
      comision: ['', [Validators.required]]
    });
  }

}
