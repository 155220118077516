<button type="button" class="btn btn-white mt-2 d-flex" (click)="onGoBackToList()">
  <span class="material-symbols-outlined">
    arrow_back
    </span>
  Volver
</button>
<form class="mt-3 col-md-8 offset-md-2" (ngSubmit)="onSave()" [formGroup]="storeForm">
  <div class="form-row">
    <div class="form-group col-md-6">
      <label for="storename">Nombre de sucursal</label>
      <input name="storename" type="text" class="form-control" formControlName="name" [class]="isValidField('name')">
      <div class="invalid-feedback">
        <span *ngIf="storeForm.get('name').errors?.required">
          This field is required.
        </span>
      </div>
    </div>
    <div class="form-group col-md-6">
      <label for="storetype">Tipo de sucursal</label>
      <select name="storetype" class="form-control" formControlName="type" id="" [class]="isValidField('type')">
        <option value="Tienda fisica">Tienda física</option>
        <option value="Cambaceo">Cambaceo</option>
        <option value="Vendedor independiente">Vendedor independiente</option>
        <option value="Expo o feria">Expo o feria</option>
        <option value="Vendedor Piso">Vendedor Piso</option>
      </select>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-6">
      <label for="phone1">Telefono 1</label>
      <input name="phone1" type="text" class="form-control" formControlName="phone_1" [class]="isValidField('phone_1')">
    </div>
    <div class="form-group col-md-6">
      <label for="phone2">Telefono 2</label>
      <input name="phone2" type="text" class="form-control" formControlName="phone_2" [class]="isValidField('phone_2')">
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-md-6">
      <label for="respname">Nombre de responsable</label>
      <input name="respname" type="text" class="form-control" formControlName="responsible_name" [class]="isValidField('responsible_name')">
    </div>
    <div class="form-group col-md-6">
      <label for="address">Calle y número</label>
      <input name="address" type="text" class="form-control" formControlName="address" [class]="isValidField('address')">
    </div>
    <div class="form-group col-md-6">
      <label for="block">Colonia</label>
      <input name="block" type="text" class="form-control" formControlName="square" [class]="isValidField('square')">
    </div>
    <div class="form-group col-md-6">
      <label for="municipality">Delegación o municipio</label>
      <input name="municipality" type="text" class="form-control" formControlName="municipality" [class]="isValidField('municipality')">
    </div>
    <div class="form-group col-md-6">
      <label for="city">Ciudad</label>
      <input name="city" type="text" class="form-control" formControlName="city" [class]="isValidField('city')">
    </div>
    <div class="form-group col-md-6">
      <label for="state">Estado</label>
      <input name="state" type="text" class="form-control" formControlName="state" [class]="isValidField('state')">
    </div>
  </div>

  <div class="form-row">
      <div class="form-group col-md-6">
        <label for="active">Activa</label>
        <select name="active" class="form-control" formControlName="active" [class]="isValidField('active')">
          <option value="yes">Sí</option>
          <option value="no">No</option>
        </select>
      </div>
      <!--
      <div class="form-group" aria-label="Disabled select example" disabled>
        <label for="invoicetemplate"></label>
        <select name="invoicetemplate" class="form-control" formControlName="template_invoice" >
          <option value="default">Por defecto</option>
        </select>
      </div>
      -->
      <div class="form-group col-md-6">
        <label for="fees">Porcentaje de comisión</label>
        <input type="number" class="form-control" formControlName="comision" step="1" min="0" max="100" [class]="isValidField('comision')">
      </div>
    </div>
  <button type="submit" class="btn bg-dark btn-block text-light">Guardar</button>
</form>
