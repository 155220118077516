<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <a class="navbar-brand" href="#">BlossomPOS</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor02"
    aria-controls="navbarColor02" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarColor02">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['employee/list']">Usuarios</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['client/list']">Clientes</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['stores/list']">Sucursales</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['products/list']">Productos</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['orders/list']">Pedidos</a>
      </li>
    </ul>
  </div>
</nav>
